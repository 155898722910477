
import { defineComponent, ref } from 'vue'

import { useRouter, useRoute } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import { useNotifications } from '@/composables/useNotifications'
export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const password = ref('')
        const passwordRepeat = ref('')
        const guid = ref('')
        const { setNewPassword, firstLogin } = useAuth()
        const { setNotification } = useNotifications()

        if (route.query.guid) {
            guid.value = route.query.guid as string
        }

        const submitForm = async () => {
            if (password.value === '') {
                setNotification('Wpisz hasło')
                return false
            }
            if (passwordRepeat.value === '') {
                setNotification('Powtórz hasło ')
                return false
            }
            if (passwordRepeat.value !== password.value) {
                setNotification('Hasła są różne')
                return false
            }
            const result = await setNewPassword(password.value, passwordRepeat.value, guid.value)
            if (result) {
                router.push({ name: 'LoginForm' })
            }
        }
        return { firstLogin, router, password, passwordRepeat, submitForm }
    },
})
